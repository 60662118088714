import { Stack } from '@mantine/core';

import { NAVBAR_HEIGHT } from '../UI/Navbar/Navbar';

import { BidResponseList } from '../components/bidResponse/BidResponseList';
import { Header } from '../components/bidResponse/Header';
import { useSetPageTitle } from '../hooks/useSetPageTitle';

export const BidResponsePage = () => {
  useSetPageTitle('Aide à la réponse');
  return (
    <Stack
      spacing="05"
      h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      w="100%"
      p="05"
      pb={0}
      sx={{
        background: `linear-gradient(180deg, #EFF8FF -5.79%, #FFFFFF 19.49%)`,
      }}
    >
      <Header />
      <BidResponseList />
    </Stack>
  );
};
