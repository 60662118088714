import * as Sentry from '@sentry/react';
import axios from 'axios';

import type { AssessmentStatus } from '../entities/AssessmentStatus';
import type { MinimalBidResponse, ProposalVersion } from '../entities/BidResponse';
import { type BidResponse } from '../entities/BidResponse';
import type Buyer from '../entities/Buyer';
import type { BuyerContact, MinimalBuyerDTO } from '../entities/Buyer';
import type { Comment } from '../entities/Comment';
import type { RichTextJson } from '../entities/Comment';
import type { Cpv } from '../entities/Cpv';
import type { DecisionLog } from '../entities/DecisionLog';
import type { FrenchDepartment } from '../entities/FrenchDepartment';
import type { InstantAnalysisAnswer } from '../entities/InstantAnalysisAnswer';
import type { InstantAnalysisQuestion } from '../entities/InstantAnalysisQuestion';
import type { Interaction } from '../entities/Interaction';
import { DecisionRenewalStatus } from '../entities/Interaction';
import { DecisionStatus } from '../entities/Interaction';
import type { QuestionsSet } from '../entities/QuestionsSet';
import type Stream from '../entities/Stream';
import type { Filters, StreamFilterSettings } from '../entities/StreamFilterSettings';
import type { StreamSection } from '../entities/StreamSection';
import type { Thread, ThreadElementType } from '../entities/Thread';
import type { User } from '../entities/User';
import type { TenderWithListData } from '../pages/Pipeline.page';
import type {
  CreateStreamSectionResponseDTO,
  DeleteCommentResponseDTO,
  GetActiveUserResponseDto,
  GetAllStreamsResponseDTO,
  GetAssessmentsRecapResponseDTO,
  GetCompanyQuestionsResponseDTO,
  GetDecisionCountsResponseDTO,
  GetOnTheFlyInstantAnalysisResponseDTO,
  FindOneTenderResponseDTO,
  GetQuestionsSetInstantAnalysisResponseDTO,
  IsInstantAnalysisAnalysableResponseDTO,
  SearchTenderDTO,
  UpdateOwnerResponseDTO,
  GetBidResponseListResponseDTO,
} from './magellan.dto';

const instance = axios.create({
  baseURL: import.meta.env.VITE_MAGELLAN_BASE_URL,
});

instance.interceptors.response.use(
  response => {
    if (response.status === 400 || response.status === 404) {
      throw new Error('Not found');
    }
    return response;
  },
  error => {
    Sentry.captureException(error, {
      tags: {
        section: error.config ? error.config.url : 'unknown', // Using the URL as a tag to be searchable in Sentry
      },
      extra: {
        request: {
          url: error.config?.url,
          method: error.config?.method,
          data: error.config?.data,
          headers: error.config?.headers,
        },
        response: {
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers,
        },
      },
    });

    return Promise.reject(error);
  },
);

export async function getBuyersByName(buyerName: string): Promise<MinimalBuyerDTO[]> {
  const response = await instance.get<MinimalBuyerDTO[]>(`/buyers?name=${buyerName}`);
  return response.data;
}

export async function getBuyerById(id: string): Promise<Buyer> {
  const response = await instance.get<Buyer>(`/buyers/${id}`);
  return response.data;
}

export async function getDepartments(): Promise<FrenchDepartment[]> {
  const response = await instance.get<FrenchDepartment[]>('/departments');
  return response.data;
}

export async function getCpvByCode(code: string): Promise<Cpv> {
  const response = await instance.get<Cpv>(`/cpv/${code}`);
  return response.data;
}

export async function searchCPV(search: string): Promise<Cpv[]> {
  const response = await instance.get<Cpv[]>(`/cpv?search=${search}&take=100`);
  return response.data;
}

export async function getTender(id: string): Promise<FindOneTenderResponseDTO> {
  const response = await instance.get<FindOneTenderResponseDTO>(`/tenders/${id}`);
  return response.data;
}

export async function getTenderForBuyer(
  buyerId: number,
  skip: number,
  take: number,
  streamIds: string[],
): Promise<SearchTenderDTO> {
  const response = await instance.post<SearchTenderDTO>(`/buyers/${buyerId}/tenders`, {
    streamIds: streamIds.map(id => parseInt(id, 10)),
    skip,
    take,
  });

  return response.data;
}

export async function getContactsForBuyer(buyerId: number): Promise<BuyerContact[]> {
  const response = await instance.get<BuyerContact[]>(`/buyers/${buyerId}/contacts`);
  return response.data;
}

export async function searchTenders(
  filter: Filters,
  skip: number,
  take: number,
  withDecision: boolean,
): Promise<SearchTenderDTO> {
  const streamFilterSettings: StreamFilterSettings = {
    ...filter,
    skip,
    take,
    withDecision,
  };
  const response = await instance.post<SearchTenderDTO>('/tenders/search', streamFilterSettings);
  return response.data;
}

export async function searchTendersPreview(streamFilterSettings: Filters): Promise<number> {
  const response = await instance.post<{ count: number }>(
    '/tenders/searchPreview',
    streamFilterSettings,
  );
  return response.data.count;
}

export async function createStream(stream: Stream): Promise<Stream> {
  const response = await instance.post<Stream>('/streams', stream);
  return response.data;
}

export async function updateStream(id: string, stream: Stream): Promise<Stream> {
  const response = await instance.put<Stream>(`/streams/${id}`, stream);
  return response.data;
}

export async function duplicateStream(id: number): Promise<Stream> {
  const response = await instance.post<Stream>(`/streams/${id}/duplicate`);
  return response.data;
}

export async function deleteStream(id: number): Promise<Stream> {
  const response = await instance.delete<Stream>(`/streams/${id}`);
  return response.data;
}

export async function getStreams(): Promise<GetAllStreamsResponseDTO> {
  const response = await instance.get<GetAllStreamsResponseDTO>('/streams');
  return response.data;
}

export async function getStream(id: number): Promise<Stream> {
  const response = await instance.get(`/streams/${id}`);
  const {
    afterPublicationDate,
    beforePublicationDate,
    afterResponseDeadlineDate,
    beforeResponseDeadlineDate,
  } = response.data.filterSettings;

  const stream = {
    ...response.data,
    filterSettings: {
      ...response.data.filterSettings,
      afterPublicationDate: afterPublicationDate ? new Date(afterPublicationDate) : null,
      beforePublicationDate: beforePublicationDate ? new Date(beforePublicationDate) : null,
      afterResponseDeadlineDate: afterResponseDeadlineDate
        ? new Date(afterResponseDeadlineDate)
        : null,
      beforeResponseDeadlineDate: beforeResponseDeadlineDate
        ? new Date(beforeResponseDeadlineDate)
        : null,
    },
  };

  return stream;
}

export async function upsertInteractionWithDecisionStatus(
  tenderId: number,
  status: DecisionStatus,
): Promise<Interaction> {
  const response = await instance.post<Interaction>(
    `tenders/${tenderId}/interactions/decisionStatus`,
    {
      status,
    },
  );

  return response.data;
}

export async function upsertInteractionWithRenewalDecisionStatus(
  tenderId: number,
  renewalStatus: DecisionRenewalStatus,
): Promise<Interaction> {
  const response = await instance.post<Interaction>(
    `tenders/${tenderId}/interactions/decisionRenewalStatus`,
    {
      status: renewalStatus,
    },
  );

  return response.data;
}

export async function upsertInteractionWithNogo(
  tenderId: number,
  reason: string,
): Promise<Interaction> {
  const response = await instance.post<Interaction>(`tenders/${tenderId}/interactions/nogo`, {
    reason,
  });

  return response.data;
}

export async function upsertInteractionWithResults(
  tenderId: number,
  reason: string,
  decisionStatus: DecisionStatus.WIN | DecisionStatus.LOSS,
): Promise<Interaction> {
  const response = await instance.post<Interaction>(`tenders/${tenderId}/interactions/result`, {
    decisionStatus,
    reason,
  });

  return response.data;
}

export async function upsertInteractionWihNote(
  tenderId: number,
  note: string,
): Promise<Interaction> {
  const response = await instance.post<Interaction>(`tenders/${tenderId}/interactions/note`, {
    note,
  });

  return response.data;
}

export async function updateInteractionOwner(
  userId: string,
  tenderId: number,
): Promise<UpdateOwnerResponseDTO> {
  const response = await instance.post<UpdateOwnerResponseDTO>(
    `tenders/${tenderId}/interactions/owner`,
    {
      userId,
    },
  );
  return response.data;
}

export async function getTendersWithInteraction(
  status?: DecisionStatus,
): Promise<TenderWithListData[]> {
  const response = await instance.get<TenderWithListData[]>(`/interactions`, {
    params: {
      status: status,
      orderBy: status === DecisionStatus.ANSWERED ? 'answeredAt' : undefined,
    },
  });

  return response.data;
}

export async function getRenewalTendersWithInteraction(
  status?: DecisionRenewalStatus,
): Promise<TenderWithListData[]> {
  const response = await instance.get<TenderWithListData[]>(`/interactions/renewals`, {
    params: {
      status: status,
      orderBy: status === DecisionRenewalStatus.TO_ANALYZE ? 'answeredAt' : undefined,
    },
  });

  return response.data;
}

export async function getDecisionCounts(): Promise<GetDecisionCountsResponseDTO> {
  const response = await instance.get<GetDecisionCountsResponseDTO>(`/interactions/counts`);

  return response.data;
}

export async function getUsers(): Promise<User[]> {
  const response = await instance.get<User[]>(`/users`);

  return response.data;
}

export async function getTenderIsAnalysable(
  tenderId: number,
): Promise<IsInstantAnalysisAnalysableResponseDTO> {
  const response = await instance.get<IsInstantAnalysisAnalysableResponseDTO>(
    `/tenders/${tenderId}/instantAnalysis/isAnalyzable`,
  );

  return response.data;
}

export async function getQuestionsSetsInstantAnalysis(
  tenderId: number,
): Promise<GetQuestionsSetInstantAnalysisResponseDTO> {
  const response = await instance.get<GetQuestionsSetInstantAnalysisResponseDTO>(
    `/tenders/${tenderId}/instantAnalysisAnswers/questionsSets`,
  );

  return response.data;
}

export async function getOnTheFlyInstantAnalysis(
  tenderId: number,
): Promise<GetOnTheFlyInstantAnalysisResponseDTO> {
  const response = await instance.get<GetOnTheFlyInstantAnalysisResponseDTO>(
    `/tenders/${tenderId}/instantAnalysisAnswers/onTheFlyQuestions`,
  );

  return response.data;
}

export async function getCompanyQuestions(): Promise<GetCompanyQuestionsResponseDTO> {
  const response = await instance.get<GetCompanyQuestionsResponseDTO>(`/instantAnalysisQuestions`);

  return response.data;
}

export async function createQuestion(
  question: string,
  questionsSetId: number,
): Promise<InstantAnalysisQuestion> {
  const response = await instance.post<InstantAnalysisQuestion>(`/instantAnalysisQuestions`, {
    question,
    questionsSetId,
  });

  return response.data;
}

export async function deleteQuestion(questionId: number): Promise<void> {
  await instance.delete(`/instantAnalysisQuestions/${questionId}`);
}

export async function requestDCE(tenderId: number): Promise<void> {
  await instance.post(`/tenders/${tenderId}/interactions/dceRequest`);
}

export async function getOnboardingFlag(): Promise<boolean> {
  const { data } = await instance.get<{ hasSeenOnboarding: boolean }>('/users/hasSeenOnboarding');
  return data.hasSeenOnboarding;
}

export async function setOnboardingDone(status: boolean): Promise<void> {
  await instance.post('/users/hasSeenOnboarding', {
    hasSeenOnboarding: status,
  });
}

export async function setOnboardingFlag(hasSeenOnboarding: boolean): Promise<boolean> {
  const res = await instance.post<boolean>('/users/hasSeenOnboarding', { hasSeenOnboarding });
  return res.data;
}

export async function getAllQuestionsSets(): Promise<QuestionsSet[]> {
  const response = await instance.get<QuestionsSet[]>(`/questionsSets`);

  return response.data;
}

export async function createQuestionsSet(
  title: string,
  questions: string[],
): Promise<QuestionsSet> {
  const response = await instance.post<QuestionsSet>(`/questionsSets`, {
    title,
    questions,
  });

  return response.data;
}

export async function updateQuestionsSet(title: string, id: number): Promise<QuestionsSet> {
  const response = await instance.put<QuestionsSet>(`/questionsSets/${id}`, {
    title,
  });

  return response.data;
}

export async function softDeleteQuestionsSet(id: number): Promise<void> {
  await instance.delete(`/questionsSets/${id}`);
}

export async function triggerInstantAnalysisWithQuestionsSets(
  tenderId: number,
  questionsSetsIds: number[],
): Promise<number> {
  try {
    const response = await instance.post(`/tenders/${tenderId}/instantAnalysis/trigger`, {
      questionsSetsIds,
    });
    return response.status;
  } catch (e: any) {
    if (e.response?.status === 422) {
      return 422;
    } else {
      throw e;
    }
  }
}

export async function triggerOnTheFlyQuestionAnalysis(
  tenderId: number,
  question: string,
): Promise<number> {
  try {
    const response = await instance.post(
      `/tenders/${tenderId}/instantAnalysis/triggerOnTheFlyQuestion`,
      {
        question,
      },
    );
    return response.status;
  } catch (e: any) {
    if (e.response?.status === 422) {
      return 422;
    } else {
      throw e;
    }
  }
}

export async function getStreamSections(): Promise<StreamSection[]> {
  const response = await instance.get<StreamSection[]>(`/streamSections`);
  return response.data;
}

export async function createStreamSection(title: string): Promise<CreateStreamSectionResponseDTO> {
  const response = await instance.post<CreateStreamSectionResponseDTO>(`/streamSections`, {
    title,
  });
  return response.data;
}

export async function updateStreamSection(
  id: number,
  title: string,
): Promise<CreateStreamSectionResponseDTO> {
  const response = await instance.put<CreateStreamSectionResponseDTO>(`/streamSections/${id}`, {
    title,
  });
  return response.data;
}

export async function deleteStreamSection(id: number): Promise<void> {
  await instance.delete(`/streamSections/${id}`);
}

export async function getActiveUser(): Promise<GetActiveUserResponseDto> {
  const response = await instance.get<GetActiveUserResponseDto>(`/users/me`);
  return response.data;
}

export async function markAsFavorite(streamId: number): Promise<void> {
  await instance.post(`/users/${streamId}/favorite`);
}

export async function unmarkAsFavorite(streamId: number): Promise<void> {
  await instance.delete(`/users/${streamId}/favorite`);
}

export async function assessInstantAnalysisAnswer(
  answerId: number,
  assessment: AssessmentStatus | null,
): Promise<void> {
  await instance.put(`/instantAnalysisAnswers/${answerId}/assessment`, {
    assessment: assessment,
  });
}

export async function getAssessmentsRecap(
  tenderId: number,
): Promise<GetAssessmentsRecapResponseDTO> {
  const response = await instance.get<GetAssessmentsRecapResponseDTO>(
    `/tenders/${tenderId}/instantAnalysisAnswers/assessmentsRecap`,
  );

  return response.data;
}

export async function updateAiRelevanceFeedback(
  answerId: number,
  aiRelevanceFeedback: string | null,
): Promise<void> {
  await instance.put(`/instantAnalysisAnswers/${answerId}/aiRelevanceFeedback`, {
    feedback: aiRelevanceFeedback,
  });
}

export async function updateManualIaAnswer(
  answerId: number,
  userAnswer: string,
): Promise<InstantAnalysisAnswer> {
  const response = await instance.put<InstantAnalysisAnswer>(
    `/instantAnalysisAnswers/${answerId}/userAnswer`,
    {
      userAnswer: userAnswer.length ? userAnswer : null,
    },
  );
  return response.data;
}

export async function deleteBuyerLogoURL(buyerId: number): Promise<void> {
  await instance.put(`/buyers/${buyerId}/logo`);
}

export async function createThread(
  tenderId: number,
  content: RichTextJson,
  iaAnswerId?: number,
): Promise<Comment> {
  const response = await instance.post<Comment>(`/comments`, {
    tenderId,
    content,
    instantAnalysisAnswerId: iaAnswerId,
  });

  return response.data;
}

export async function createComment(
  tenderId: number,
  threadId: number,
  content: RichTextJson,
  iaAnswerId?: number,
): Promise<Comment> {
  const response = await instance.post<Comment>(`/comments`, {
    tenderId,
    threadId,
    content,
    instantAnalysisAnswerId: iaAnswerId,
  });

  return response.data;
}

export async function getThreads(tenderId: number, type: ThreadElementType): Promise<Thread[]> {
  const response = await instance.get<Thread[]>(`/threads?tenderId=${tenderId}&type=${type}`);

  return response.data;
}

export async function deleteComment(commentId: number): Promise<DeleteCommentResponseDTO> {
  const response = await instance.delete<DeleteCommentResponseDTO>(`/comments/${commentId}`);

  return response.data;
}

export async function updateComment(commentId: number, content: RichTextJson): Promise<Comment> {
  const response = await instance.put<Comment>(`/comments/${commentId}`, {
    content,
  });

  return response.data;
}

export async function getDecisionLogs(tenderId: number): Promise<DecisionLog[]> {
  const response = await instance.get<DecisionLog[]>(`/tenders/${tenderId}/logDecisions`);

  return response.data;
}

export async function getBidResponseList(): Promise<MinimalBidResponse[]> {
  const response = await instance.get<GetBidResponseListResponseDTO>(`/bidResponses`);
  return response.data.results;
}

export async function createBidResponse(formData: FormData): Promise<BidResponse> {
  const response = await instance.post<BidResponse>(`/bidResponses`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export async function updateBidResponse(
  bidResponseId: number,
  additionalContext?: string,
  companyRelationWithBuyer?: string,
  companyResponseStrategy?: string,
): Promise<BidResponse> {
  const response = await instance.put(`/bidResponses/${bidResponseId}`, {
    additionalContext,
    companyRelationWithBuyer,
    companyResponseStrategy,
  });
  return response.data;
}

export async function getBidResponse(id: number): Promise<BidResponse> {
  const response = await instance.get<BidResponse>(`/bidResponses/${id}`);
  return response.data;
}

export async function createProposalVersion(
  bidResponseId: number,
  formData: FormData,
): Promise<ProposalVersion> {
  const response = await instance.post(
    `/bidResponses/${bidResponseId}/proposalVersions`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
}

export async function getProposalVersion(bidResponseId: number, proposalVersionId: number) {
  const response = await instance.get<ProposalVersion>(
    `/bidResponses/${bidResponseId}/proposalVersions/${proposalVersionId}`,
  );
  return response.data;
}

export async function deleteBidRequirement(
  bidResponseId: number,
  requirementId: number,
  deletionReason?: string,
) {
  await instance.post(`/bidResponses/${bidResponseId}/bidExpectations/${requirementId}/delete`, {
    deletionReason,
  });
}
