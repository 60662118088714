import type { FileWithPath } from '@mantine/dropzone';

import { useMutation } from '@tanstack/react-query';

import { createProposalVersion } from '../../../api/magellan';
import { ResponseDocType } from '../../../entities/BidResponse';

type CreateProposalVersionRequestDto = {
  bidResponseId: number;
  proposalFiles: FileWithPath[];
  complementaryFiles: FileWithPath[];
  onSuccessCallback?: (data: any) => void;
};

export const useCreateProposalVersion = () => {
  const createMutation = useMutation({
    mutationFn: async ({
      bidResponseId,
      proposalFiles,
      complementaryFiles,
    }: CreateProposalVersionRequestDto) => {
      const formData = new FormData();

      proposalFiles.forEach(file => {
        formData.append(ResponseDocType.PROPOSAL_TECHNICAL_SUBMISSION, file, encodeURIComponent(file.name));
      });
      complementaryFiles.forEach(file => {
        formData.append(ResponseDocType.PROPOSAL_APPENDICES, file, encodeURIComponent(file.name));
      });

      const res = await createProposalVersion(bidResponseId, formData);
      return res;
    },
    onSuccess: (data, { onSuccessCallback }) => {
      onSuccessCallback && onSuccessCallback(data);
    },
  });

  return {
    creaProposalVersion: createMutation.mutate,
  };
};
