import { useState } from 'react';

import type { MantineTheme } from '@mantine/core';
import { Stack, Title, Text, Group, Box, useMantineTheme, Badge, Button } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconX } from '@tabler/icons-react';

import { AssessmentStatus } from '../../../entities/AssessmentStatus';
import type { ExpectationEvaluation, JudgementCriteria } from '../../../entities/BidResponse';
import { AssessmentBadge } from '../../notices/InstantAnalysis/AssessmentBadge';
import type { CriteriaWithChildren } from './CriterionCard';
import { createCriterionTree } from './CriterionCard';
import { MISSING_EVALUATION, type RequirementEvaluationFilter } from './SimulationTab';

type EvalCardProps = {
  filter: boolean;
  missingElements: number;
  setFilter: (filter: RequirementEvaluationFilter) => void;
  removeFilter: (type: string) => void;
  clearFilter: () => void;
  judgementCriterion: JudgementCriteria[];
  requirementEvaluations: ExpectationEvaluation[];
};

export const GridFilters = ({
  filter,
  setFilter,
  removeFilter,
  clearFilter,
  judgementCriterion,
  requirementEvaluations,
}: EvalCardProps) => {
  const criterionTree = createCriterionTree(judgementCriterion);
  const [activeFilters, setActiveFilters] = useState<string[] | null>(null);

  const handleNewFilter = (
    callback: RequirementEvaluationFilter['callback'] | null,
    type: string,
  ) => {
    if (callback === null) {
      removeFilter(type);
      setActiveFilters(prev => prev?.filter(f => f !== type) || null);
    } else {
      setFilter({ type, callback });
      setActiveFilters(prev => (prev ? [...prev, type] : [type]));
    }
  };
  const handleClearFilter = () => {
    clearFilter();
    setActiveFilters(null);
  };

  return (
    <Group
      align="flex-start"
      noWrap
      spacing="06"
      px="06"
      py="05"
      sx={{
        borderRadius: `12px 12px 0px 0px`,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      }}
    >
      <Stack spacing="04" align="flex-start">
        <Group spacing="03" noWrap>
          <Title order={5} c="dark.9">
            Évaluation
          </Title>
          <Box h="16px" sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[2]}` })} />
          <Text variant="sm" fw={400} c="dark.9">
            Filter par
          </Text>
        </Group>
        <Group spacing="03">
          {criterionTree.map(criteria => (
            <FilterCriteriaButton
              key={criteria.id}
              selected={activeFilters?.includes(criteria.title)}
              onClick={handleNewFilter}
              criteriaWithChildren={criteria}
              requirementEvaluation={requirementEvaluations}
            />
          ))}
          <FilterMissingElementsButton
            selected={activeFilters?.includes('missing element')}
            onClick={handleNewFilter}
            requirementEvaluation={requirementEvaluations}
          />
          {filter && <ClearFilterButton onClick={handleClearFilter} />}
        </Group>
      </Stack>
    </Group>
  );
};

type FilterButtonProps = {
  onClick?: (callback: RequirementEvaluationFilter['callback'] | null, type: string) => void;
  selected?: boolean;
  requirementEvaluation: ExpectationEvaluation[];
};

const FilterCriteriaButton = ({
  onClick,
  criteriaWithChildren,
  selected = false,
  requirementEvaluation,
}: FilterButtonProps & {
  criteriaWithChildren: CriteriaWithChildren;
}) => {
  const { hovered, ref } = useHover<HTMLButtonElement>();
  const filterMissingElements = (e: ExpectationEvaluation) =>
    e.bidExpectation.judgementCriteriaId === criteriaWithChildren.id ||
    criteriaWithChildren.subCriterion.some(sub => sub.id === e.bidExpectation.judgementCriteriaId);
  const color = criteriaWithChildren.color?.color || 'gray';
  const count = requirementEvaluation.filter(filterMissingElements).length;
  if (!count) {
    return null;
  }

  return (
    <Button
      ref={ref}
      variant="outline"
      radius="md"
      c="gray.9"
      disabled={!onClick}
      leftIcon={
        <Box>
          <Box
            w="10px"
            h="10px"
            bg={`${color}.5`}
            sx={theme => ({
              rotate: '45deg',
              borderRadius: theme.radius.xs,
              border: `1px solid ${theme.colors[color][6]}`,
              boxShadow:
                '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset;',
            })}
          />
        </Box>
      }
      rightIcon={count && <AmountBadge count={count} isSelected={hovered || selected} />}
      onClick={() =>
        onClick && onClick(selected ? null : filterMissingElements, criteriaWithChildren.title)
      }
      styles={{
        inner: {
          justifyContent: 'flex-start',
        },
      }}
      sx={(theme: MantineTheme) => ({
        alignContent: 'flex-start',
        border: selected
          ? `2px solid ${theme.colors.primary[7]}`
          : `1px solid ${theme.colors.gray[3]}`,
        backgroundColor: selected ? theme.colors.primary[0] : 'white',
        ':hover': {
          backgroundColor: theme.colors.primary[0],
        },
      })}
    >
      <Text variant="sm" fw="500">
        {criteriaWithChildren.title}
      </Text>
    </Button>
  );
};

function FilterMissingElementsButton({
  onClick,
  selected = false,
  requirementEvaluation,
}: FilterButtonProps) {
  const { hovered, ref } = useHover<HTMLButtonElement>();
  const filterMissingElements = (e: ExpectationEvaluation) =>
    e.retrievedAnswerFromProposal === MISSING_EVALUATION;
  const count = requirementEvaluation.filter(filterMissingElements).length;

  return (
    <Button
      ref={ref}
      variant="outline"
      radius="md"
      c="gray.9"
      disabled={!onClick}
      leftIcon={<AssessmentBadge assessment={AssessmentStatus.BLOCKING_POINT} isSelected={true} />}
      rightIcon={count && <AmountBadge count={count} isSelected={hovered || selected} />}
      onClick={() => onClick && onClick(selected ? null : filterMissingElements, 'missing element')}
      styles={{
        inner: {
          justifyContent: 'flex-start',
        },
      }}
      sx={(theme: MantineTheme) => ({
        alignContent: 'flex-start',
        border: selected
          ? `2px solid ${theme.colors.primary[7]}`
          : `1px solid ${theme.colors.gray[3]}`,
        backgroundColor: selected ? theme.colors.primary[0] : 'white',
        ':hover': {
          backgroundColor: theme.colors.primary[0],
        },
      })}
    >
      <Text variant="sm" fw="500">
        Élements de réponse manquants
      </Text>
    </Button>
  );
}

const AmountBadge = ({ count, isSelected = false }: { count: number; isSelected?: boolean }) => {
  const theme = useMantineTheme();
  const color = isSelected ? theme.colors.primary[2] : theme.colors.gray[1];

  return (
    <Badge c="gray.9" px={6} sx={{ backgroundColor: color }}>
      {count}
    </Badge>
  );
};

const ClearFilterButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="subtle"
      c="gray.8"
      size="sm"
      onClick={onClick}
      leftIcon={<IconX size={16} stroke={1.5} />}
    >
      Réinitialiser
    </Button>
  );
};
