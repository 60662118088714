import type { MantineColor } from '@mantine/core';
import { Box, Group, Stack, Text } from '@mantine/core';
import type { MantineShade } from 'mantine-react-table';

import { BrandedCard } from '../../../UI/BrandedCard/BrandedCard';

import type { CriteraColor, JudgementCriteria } from '../../../entities/BidResponse';

// const criterion: JudgementCriteria[] = [
//   {
//     id: 34,
//     title: 'Prix des prestations',
//     weight: 50,
//     bidResponseId: 33,
//     parentId: null,
//   },
//   {
//     id: 13,
//     title:
//       'Méthodologie pédagogique, modalités d’exécution des prestations et qualité/expériences des formateurs',
//     weight: 30,
//     bidResponseId: 33,
//     parentId: 34,
//   },
//   {
//     id: 14,
//     title:
//       'Méthodologie pédagogique, modalités d’exécution des prestations et qualité/expériences des formateurs',
//     weight: 30,
//     bidResponseId: 33,
//     parentId: 34,
//   },
//   {
//     id: 15,
//     title:
//       'Méthodologie pédagogique, modalités d’exécution des prestations et qualité/expériences des formateurs',
//     weight: 30,
//     bidResponseId: 33,
//     parentId: 34,
//   },
//   {
//     id: 35,
//     title:
//       'Méthodologie pédagogique, modalités d’exécution des prestations et qualité/expériences des formateurs',
//     weight: 30,
//     bidResponseId: 33,
//     parentId: null,
//   },
//   {
//     id: 3,
//     title:
//       'Méthodologie pédagogique, modalités d’exécution des prestations et qualité/expériences des formateurs',
//     weight: 30,
//     bidResponseId: 33,
//     parentId: 35,
//   },
//   {
//     id: 4,
//     title:
//       'Méthodologie pédagogique, modalités d’exécution des prestations et qualité/expériences des formateurs',
//     weight: 30,
//     bidResponseId: 33,
//     parentId: 35,
//   },
//   {
//     id: 5,
//     title:
//       'Méthodologie pédagogique, modalités d’exécution des prestations et qualité/expériences des formateurs',
//     weight: 30,
//     bidResponseId: 33,
//     parentId: 35,
//   },
//   {
//     id: 36,
//     title: 'Gestion des délais et facturations dans le cadre des annulations et reports de session',
//     weight: 20,
//     bidResponseId: 33,
//     parentId: null,
//   },
//   {
//     id: 37,
//     title: 'Responsabilité sociétale et environnementale',
//     weight: 5,
//     bidResponseId: 33,
//     parentId: 36,
//   },
//   {
//     id: 38,
//     title: 'Responsabilité sociétale et environnementale',
//     weight: 5,
//     bidResponseId: 33,
//     parentId: 36,
//   },
//   {
//     id: 39,
//     title: 'Responsabilité sociétale et environnementale',
//     weight: 5,
//     bidResponseId: 33,
//     parentId: 36,
//   },
//   {
//     id: 40,
//     title: 'Responsabilité sociétale et environnementale',
//     weight: 5,
//     bidResponseId: 33,
//     parentId: 36,
//   },
// ];

type CriterionCardProps = {
  criterion: JudgementCriteria[];
};

export const CriterionCard = ({ criterion }: CriterionCardProps) => {
  if (!criterion.length) {
    return null;
  }

  const sortedCriterion = createCriterionTree(criterion);

  return (
    <BrandedCard title="Critères de notation">
      <Stack maw="640px">
        <Group noWrap w="100%" spacing={0}>
          {sortedCriterion.map((c, index) => {
            const isFirst = index === 0;
            const isLast = index === sortedCriterion.length - 1;
            return (
              <ParentCriteriaWeight
                key={c.id}
                criteria={c}
                isFirst={isFirst}
                isLast={isLast}
                subCriterion={c.subCriterion}
              />
            );
          })}
        </Group>
        <CriterionTree criterion={sortedCriterion} />
      </Stack>
    </BrandedCard>
  );
};

export type CriteriaWithChildren = JudgementCriteria & { subCriterion: JudgementCriteria[] };

export function createCriterionTree(criterion: JudgementCriteria[]): CriteriaWithChildren[] {
  const colors: CriteraColor['color'][] = ['teal', 'blue', 'orange', 'violet'];
  const colorShades: CriteraColor['shade'][] = [1, 3, 6, 9];

  const criterionTree: CriteriaWithChildren[] = [];
  const criterionMap = new Map<number, JudgementCriteria[]>();

  criterion.forEach(c => {
    if (!c.parentId) {
      criterionTree.push({
        ...c,
        color: { color: colors.pop() },
        subCriterion: [],
      });
    }
  });

  criterion.forEach(c => {
    if (c.parentId) {
      const parent = criterionMap.get(c.parentId) || [];
      const parentColor = criterionTree.find(parent => parent.id === c.parentId)?.color?.color;
      parent.push({
        ...c,
        color: {
          color: parentColor,
          shade: colorShades[parent.length],
        },
      });
      criterionMap.set(c.parentId, parent);
    }
  });

  return criterionTree.map(c => ({
    ...c,
    subCriterion: criterionMap.get(c.id) || [],
  }));
}

type CriteriaWeightProps = {
  criteria: JudgementCriteria;
  isFirst: boolean;
  isLast: boolean;
  subCriterion?: JudgementCriteria[];
};

const ParentCriteriaWeight = ({ criteria, isFirst, isLast, subCriterion }: CriteriaWeightProps) => {
  const color = criteria.color?.color;
  if (!subCriterion?.length) {
    return (
      <Box
        key={criteria.id}
        w={`${criteria.weight}%`}
        h="30px"
        bg={`${color}.5`}
        sx={theme => ({
          boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 4px 0px rgba(255, 255, 255, 0.20) inset`,
          borderTopLeftRadius: isFirst ? theme.radius.md : 0,
          borderBottomLeftRadius: isFirst ? theme.radius.md : 0,
          borderBottomRightRadius: isLast ? theme.radius.md : 0,
          borderTopRightRadius: isLast ? theme.radius.md : 0,
          marginLeft: !isFirst ? `4px` : 'none',
          marginRight: !isLast ? `4px` : 'none',
        })}
      />
    );
  }

  return (
    <Group
      noWrap
      w={`${criteria.weight}%`}
      spacing={0}
      sx={theme => ({
        marginLeft: !isFirst ? `4px` : 'none',
        marginRight: !isLast ? `4px` : 'none',
        borderTopLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomRightRadius: isLast ? theme.radius.md : 0,
        borderTopRightRadius: isLast ? theme.radius.md : 0,
        boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 4px 0px rgba(255, 255, 255, 0.20) inset`,
      })}
    >
      {subCriterion.map((c, index) => {
        const isFirstChild = index === 0 && isFirst;
        const isLastCHild = index === subCriterion.length - 1 && isLast;

        return (
          <ChildCriteria
            key={c.id}
            criteria={c}
            isFirst={isFirstChild}
            isLast={isLastCHild}
            parentColor={color}
            totalWeight={criteria.weight}
          />
        );
      })}
    </Group>
  );
};

type ChildCriteriaWeightProps = CriteriaWeightProps & {
  totalWeight: number;
  parentColor?: MantineColor;
};
const ChildCriteria = ({
  criteria,
  isFirst,
  isLast,
  parentColor,
  totalWeight,
}: ChildCriteriaWeightProps) => {
  const width = (criteria.weight * 100) / totalWeight;
  return (
    <Box
      key={criteria.id}
      w={`${width}%`}
      h="30px"
      bg={`${parentColor}.${criteria.color?.shade}`}
      sx={theme => ({
        borderTopLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomLeftRadius: isFirst ? theme.radius.md : 0,
        borderBottomRightRadius: isLast ? theme.radius.md : 0,
        borderTopRightRadius: isLast ? theme.radius.md : 0,
      })}
    />
  );
};

type CriterionTreeProps = {
  criterion: CriteriaWithChildren[];
};

const CriterionTree = ({ criterion }: CriterionTreeProps) => {
  return (
    <Stack spacing="02">
      {criterion.map(c => {
        const color = c.color?.color;
        if (!color) {
          return null;
        }

        return (
          <Box key={c.id}>
            <CriteriaWithMarker title={c.title} weight={c.weight} color={color} shade={5} />
            {c.subCriterion.map(sub => (
              <CriteriaWithMarker
                key={sub.id}
                title={sub.title}
                weight={sub.weight}
                color={color}
                shade={sub.color?.shade}
                p={'03'}
              />
            ))}
          </Box>
        );
      })}
    </Stack>
  );
};

type CriteriaProps = {
  title: string;
  weight: number;
  color: MantineColor;
  shade?: MantineShade;
  p?: string;
  maw?: string;
};

export const CriteriaWithMarker = ({ title, weight, color, shade, p, maw }: CriteriaProps) => {
  if (!shade) {
    return null;
  }
  return (
    <Group noWrap spacing="02" maw={maw}>
      <Box p={p}>
        <Box
          w="10px"
          h="10px"
          bg={`${color}.${shade}`}
          sx={theme => ({
            rotate: '45deg',
            borderRadius: theme.radius.xs,
            border: `1px solid ${theme.colors[color][shade + 1]}`,
            boxShadow:
              '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset;',
          })}
        />
      </Box>
      <Text variant="sm" fw={400} c="gray.6" truncate>
        {title}
      </Text>
      <Text variant="sm" fw={500} c={maw ? 'gray.6' : 'gray.9'}>
        {weight}%
      </Text>
    </Group>
  );
};
