import { useSuspenseQuery } from '@tanstack/react-query';

import { getBidResponseList } from '../../api/magellan';
import { queryClient } from '../../api/queryClient';

export function useBidResponseList() {
  const queryKey = [getBidResponseList.name];
  const queryFn = async () => getBidResponseList();
  const { data: bidResponseList } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const refetchBidResponseList = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return { bidResponseList, refetchBidResponseList };
}
