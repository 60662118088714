import { Badge, Group, Indicator, Text } from '@mantine/core';

import { IconActivity, IconMessageCircle2, IconNotes } from '@tabler/icons-react';

import { Counter } from '../../../UI/Counter/Counter';

import { useNoticeContext } from '../Notice.context';
import { useThreads } from './Comments/useThreads';

export type NoticeSidePanelTabValues = 'note' | 'comments' | 'activity';

type SidePanelHeaderProps = {
  setActiveTab: (tab: NoticeSidePanelTabValues) => void;
  activeTab?: NoticeSidePanelTabValues | null;
  oppened: boolean;
};

export function SidePanelHeader({ oppened, setActiveTab, activeTab }: SidePanelHeaderProps) {
  const { tender } = useNoticeContext();
  const { commentCounter } = useThreads(tender.id);
  const handleTabClick = (tab: NoticeSidePanelTabValues) => {
    if (oppened) {
      setActiveTab(tab);
    } else {
      setActiveTab(tab);
    }
  };

  const hasNote = !!tender.interaction?.note;

  return (
    <Group noWrap h="fit-content" spacing="01" px={activeTab ? '03' : 0}>
      <HeaderTab
        icon={
          <Indicator color="primary.6" offset={4} size={8} withBorder disabled={!hasNote} mt="4px">
            <IconNotes size={16} />
          </Indicator>
        }
        label="Note"
        value="note"
        activeTab={activeTab}
        onClick={handleTabClick}
      />
      <HeaderTab
        icon={<IconMessageCircle2 size={16} />}
        label={
          <Group noWrap spacing="01">
            <Text>Commentaires</Text>
            <Badge variant="filled" size="xs">
              new
            </Badge>
          </Group>
        }
        value="comments"
        activeTab={activeTab}
        onClick={handleTabClick}
        counter={commentCounter}
      />
      <HeaderTab
        icon={<IconActivity size={16} />}
        label="Activité"
        value="activity"
        activeTab={activeTab}
        onClick={handleTabClick}
      />
    </Group>
  );
}

type HeaderTabProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  counter?: number;
  value: NoticeSidePanelTabValues;
  activeTab?: NoticeSidePanelTabValues | null;
  onClick: (tabValue: NoticeSidePanelTabValues) => void;
};

function HeaderTab({ icon, label, counter, value, activeTab, onClick }: HeaderTabProps) {
  const isActive = value === activeTab;

  return (
    <Group
      noWrap
      h="22px"
      spacing="01"
      px="02"
      py="00"
      sx={theme => ({
        background: isActive ? theme.colors.gray[0] : 'transparent',
        borderRadius: theme.radius.md,
        ':hover': { cursor: 'pointer', background: theme.colors.gray[0] },
      })}
      c="gray.5"
      onClick={() => onClick(value)}
    >
      {icon}
      <Group noWrap spacing="02">
        <Text variant="sm" fw={isActive ? 500 : 400} c={isActive ? 'gray.9' : 'gray.7'}>
          {label}
        </Text>
        {counter && <Counter value={counter} color="gray" size="xs" variant="subtle" />}
      </Group>
    </Group>
  );
}
