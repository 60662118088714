import { useState } from 'react';

import {
  Box,
  Group,
  Modal,
  Skeleton,
  Stack,
  Table,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';

import { IconTrash } from '@tabler/icons-react';
import Markdown from 'react-markdown';

import { Button } from '../../../UI/Button/Button';
import { TextArea } from '../../../UI/TextArea/TextArea';

import AIIconSparkle from '../../../assets/ai_icons/sparkle.svg?react';
import type {
  BidRequirement,
  ExpectationEvaluation,
  JudgementCriteria,
} from '../../../entities/BidResponse';
import type { DataPointSource } from '../../../entities/DataPointSource';
import EmptyPlaceholder from '../../EmptyPlaceholder';
import { SourcesContent } from '../../notices/InstantAnalysis/SourcesContent';
import { createCriterionTree, CriteriaWithMarker } from './CriterionCard';
import { MISSING_EVALUATION, type RequirementEvaluationFilter } from './SimulationTab';
import { SourceButton } from './SourceButton';
import { useBidRequirement } from './useBidRequirement';
import { useProposalVersion } from './useProposalVersion.hook';

export type BidRequirementEvaluationGrid = {
  bidResponseId: number;
  proposalVersionId: number;
  requirementEvaluations: ExpectationEvaluation[];
  judgementCriterion: JudgementCriteria[];
  filterFunction: RequirementEvaluationFilter['callback'][] | null;
};

export const BidRequirementEvaluationGrid = ({
  bidResponseId,
  requirementEvaluations,
  judgementCriterion,
  filterFunction,
  proposalVersionId,
}: BidRequirementEvaluationGrid) => {
  const theme = useMantineTheme();
  const thStyle = { borderBottom: `1px solid ${theme.colors.gray[1]}`, background: 'white' };
  const filteredRequirements = filterFunction
    ? filterFunction.reduce((acc, filter) => acc.filter(filter), requirementEvaluations)
    : requirementEvaluations;
  return (
    <Box
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        paddingLeft: theme.spacing['03'],
        paddingRight: theme.spacing['03'],
      })}
    >
      {filteredRequirements.length === 0 ? (
        <EmptyPlaceholder title={'Aucun élements'} />
      ) : (
        <Table>
          <thead>
            <tr>
              <th style={thStyle}>
                <Text variant="xs" fw={500} c="gray.6" pl="01">
                  #
                </Text>
              </th>
              <th style={thStyle}>
                <Text variant="xs" fw={500} c="gray.6">
                  Éxigences de l'acheteur
                </Text>
              </th>
              <th style={thStyle}>
                <Text variant="xs" fw={500} c="gray.6">
                  Élement de l'offre
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredRequirements.map((requirementEvaluation, index) => (
              <RequirementEvaluationRow
                key={requirementEvaluation.id}
                bidResponseId={bidResponseId}
                index={index}
                requirementEvaluation={requirementEvaluation}
                judgementCriterion={judgementCriterion}
                proposalVersionId={proposalVersionId}
              />
            ))}
          </tbody>
        </Table>
      )}
    </Box>
  );
};

type RequirementEvaluationRowProps = {
  index: number;
  bidResponseId: number;
  requirementEvaluation: ExpectationEvaluation;
  judgementCriterion: JudgementCriteria[];
  proposalVersionId: number;
};

const RequirementEvaluationRow = ({
  requirementEvaluation,
  bidResponseId,
  index,
  judgementCriterion,
  proposalVersionId,
}: RequirementEvaluationRowProps) => {
  const theme = useMantineTheme();
  const { hovered, ref } = useHover<HTMLTableRowElement>();
  const tdStyle = {
    borderBottom: `1px solid ${theme.colors.gray[1]}`,
    background: hovered ? theme.colors.gray[0] : 'white',
    padding: theme.spacing['03'],
  };
  const source: DataPointSource = {
    docFilePath: requirementEvaluation.bidExpectation.source,
    originalChunkExtract: requirementEvaluation.bidExpectation.originalChunkExtract,
    pageNumber: requirementEvaluation.bidExpectation.pageNumber || null,
  };
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Box component="tr" ref={ref}>
      <DeletionModal
        opened={opened}
        close={close}
        bidRequirementId={requirementEvaluation.bidExpectation.id}
        bidResponseId={bidResponseId}
        proposalVersionId={proposalVersionId}
      />
      <td style={{ ...tdStyle, width: '20px' }}>
        {hovered ? (
          <ThemeIcon
            color="red.5"
            variant="light"
            size="sm"
            p="00"
            onClick={open}
            sx={{
              border: 'none',
              background: 'transparent',
              ':hover': {
                cursor: 'pointer',
                background: theme.colors.gray[2],
                borderRadius: theme.radius.sm,
                color: 'red.7',
              },
            }}
          >
            <IconTrash />
          </ThemeIcon>
        ) : (
          <Text variant="sm" fw={400} c="gray.5" pl="01">
            {index + 1}
          </Text>
        )}
      </td>
      <td style={{ ...tdStyle, width: '36%' }}>
        <BidRequirementCell
          bidRequirement={requirementEvaluation.bidExpectation}
          judgmentCriterion={judgementCriterion}
          source={source}
        />
      </td>
      <td style={{ ...tdStyle, alignContent: 'flex-start' }}>
        <RequirementEvaluationCell requirementEvaluation={requirementEvaluation} />
      </td>
    </Box>
  );
};

type BidRequirementCellProps = {
  bidRequirement: BidRequirement;
  judgmentCriterion: JudgementCriteria[];
  source: DataPointSource;
};

const BidRequirementCell = ({
  bidRequirement,
  judgmentCriterion,
  source,
}: BidRequirementCellProps) => {
  if (!bidRequirement) {
    return <Loader />;
  }
  const criterionTree = createCriterionTree(judgmentCriterion);

  let relatedJudgementCriteria: JudgementCriteria | undefined;

  criterionTree.map(c => {
    if (c.id === bidRequirement.judgementCriteriaId) {
      relatedJudgementCriteria = c;
    } else {
      c.subCriterion.map(sub => {
        if (sub.id === bidRequirement.judgementCriteriaId) {
          relatedJudgementCriteria = sub;
        }
      });
    }
  });

  return (
    <Stack spacing="02" pr="09">
      <Group noWrap spacing="02" align="flex-start">
        <Text variant="sm" fw={500} c="gray.9" sx={{ p: { margin: 0 } }}>
          <Markdown>{bidRequirement.title}</Markdown>
        </Text>
        <SourceButton source={source} />
      </Group>
      {relatedJudgementCriteria && relatedJudgementCriteria?.color?.color && (
        <CriteriaWithMarker
          title={relatedJudgementCriteria?.title}
          weight={relatedJudgementCriteria?.weight}
          color={relatedJudgementCriteria?.color?.color}
          shade={relatedJudgementCriteria?.color?.shade || 5}
          maw="400px"
        />
      )}
    </Stack>
  );
};

type RequirementEvaluationCellProps = {
  requirementEvaluation: ExpectationEvaluation;
};

const RequirementEvaluationCell = ({ requirementEvaluation }: RequirementEvaluationCellProps) => {
  if (!requirementEvaluation.retrievedAnswerFromProposal) {
    return <Loader />;
  }

  return (
    <Stack>
      <Text variant="sm" fw={400} c="gray.9">
        <Markdown>{requirementEvaluation.retrievedAnswerFromProposal}</Markdown>
      </Text>
      {requirementEvaluation.sources &&
        requirementEvaluation.retrievedAnswerFromProposal !== MISSING_EVALUATION && (
          <SourcesContent sources={requirementEvaluation.sources} />
        )}
    </Stack>
  );
};

const Loader = () => {
  return (
    <Group noWrap spacing="02">
      <AIIconSparkle />
      <Skeleton height={10} maw={200} />
    </Group>
  );
};

type DeletionModalProps = {
  opened: boolean;
  close: () => void;
  bidRequirementId: number;
  bidResponseId: number;
  proposalVersionId: number;
};

const DeletionModal = ({
  opened,
  close,
  bidRequirementId,
  bidResponseId,
  proposalVersionId,
}: DeletionModalProps) => {
  const [deletionReason, setDeletionReason] = useState('');
  const { deleteBidRequirement } = useBidRequirement();
  const { refetchProposalVersion } = useProposalVersion(bidResponseId, proposalVersionId);
  const handleDelete = () => {
    deleteBidRequirement({
      bidResponseId,
      bidRequirementId,
      deletionReason,
      onSuccessCallback: () => {
        refetchProposalVersion();
      },
    });
    close();
  };

  const handleClose = () => {
    setDeletionReason('');
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title="Supprimer cette éxigence ?"
      styles={theme => ({
        body: {
          paddingTop: 0,
        },
        content: {
          borderRadius: theme.radius.md,
        },
      })}
    >
      <Stack spacing="03">
        <Text variant="sm" fw={400} c="gray.6">
          Attention cette action est irréversible.
        </Text>
        <TextArea
          value={deletionReason}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDeletionReason(event.currentTarget.value)
          }
          placeholder="Pourquoi cette éxigence n'était-elle pas pertinente pour vous ?"
        />
        <Group noWrap position="right">
          <Button variant="white" onClick={handleClose}>
            Annuler
          </Button>
          <Button color="red" onClick={handleDelete}>
            Supprimer
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
