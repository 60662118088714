import { Box } from '@mantine/core';

import { TenderTitle } from '../../UI/TenderTitle/TenderTitle';

type HeaderProps = {
  buyerOriginalName: string;
  tenderTitle: string;
};

export const Header = (props: HeaderProps) => {
  return (
    <Box p="05" pb="02">
      <TenderTitle {...props} size="lg" />
    </Box>
  );
};
