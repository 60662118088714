import type { PreviousPageData } from '../../components/HeaderActionBar';
import type { NoticeSidePanelTabValues } from '../../components/notices/SidePanel/SidePanelHeader';
import { encodeObjectToBase64, removeEmoji } from '../../utils';

export enum UrlHashTypes {
  LOCATION_DATA = 'LOCATION_DATA',
  SIDEPANEL_ACTION = 'SIDEPANEL_ACTION',
}
export type UrlHashValues = {
  [UrlHashTypes.LOCATION_DATA]: PreviousPageData;
  [UrlHashTypes.SIDEPANEL_ACTION]: NoticeSidePanelTabValues;
};

export type UrlHashObject = Partial<Record<UrlHashTypes, any>>;

export class UrlHashBuilder {
  private urlHash: UrlHashObject;

  constructor() {
    this.urlHash = {};
  }

  public addLocationData(): UrlHashBuilder {
    const documentTitle = document.title;
    const label = documentTitle.startsWith('Flux -')
      ? documentTitle.replace('Flux - ', '')
      : documentTitle;

    const locationData: PreviousPageData = {
      pathname: location.pathname,
      title: removeEmoji(label),
    };

    this.urlHash[UrlHashTypes.LOCATION_DATA] = locationData;
    return this;
  }

  public addSidePanelActionData(tabToOpen: NoticeSidePanelTabValues): UrlHashBuilder {
    this.urlHash[UrlHashTypes.SIDEPANEL_ACTION] = tabToOpen;
    return this;
  }

  public build(): string {
    return encodeObjectToBase64(this.urlHash);
  }
}
