import { useCallback } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getOnTheFlyInstantAnalysis,
  triggerOnTheFlyQuestionAnalysis as triggerOnTheFlyQuestionAnalysisApi,
} from '../api/magellan';
import { queryClient } from '../api/queryClient';
import { useInstantAnalysis } from './useInstantAnalysis';

export function useOnTheFlyQuestionsSetsAnalysis(tenderId: number) {
  const queryKey = [getOnTheFlyInstantAnalysis.name, tenderId];
  const queryFn = async () => getOnTheFlyInstantAnalysis(tenderId);
  const { data: otfAnalysis } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey });

  const { status, retry, onTriggerInstantAnalysis } = useInstantAnalysis(
    refetch,
    otfAnalysis.status,
  );

  const triggerOnTheFlyQuestionAnalysis = useCallback(
    async (question: string) => {
      const res = await triggerOnTheFlyQuestionAnalysisApi(tenderId, question);
      if (res === 201) {
        onTriggerInstantAnalysis();
      }
    },
    [onTriggerInstantAnalysis, tenderId],
  );

  return {
    status,
    answers: otfAnalysis.answers,
    triggerOnTheFlyQuestionAnalysis,
    retry,
    refetch,
  };
}
