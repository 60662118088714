import { useMantineTheme } from '@mantine/core';
import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';

import { CreateStreamSectionModal } from './CreateStreamSectionModal';
import { DeleteModal } from './DeleteConfirmationModal';
import { NoGoReasonModal } from './NoGoReasonModal';
import { OnboardingModal } from './OnboardingModal';
import { StreamDiscardModal } from './StreamDiscardModal';
import { UpdateQuestionsSetsModal } from './UpdateQuestionsSetsModal';
import { WinLossReasonModal } from './WinLossReasonModal';

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof tengoModals;
  }
}

export type TengoModals = keyof typeof tengoModals;

const tengoModals = {
  nogoReason: NoGoReasonModal,
  winLossReason: WinLossReasonModal,
  streamDelete: DeleteModal,
  streamDiscard: StreamDiscardModal,
  commentDelete: DeleteModal,
  onboarding: OnboardingModal,
  updateQuestionsSets: UpdateQuestionsSetsModal,
  createStreamSection: CreateStreamSectionModal,
  /* add your modal here */
};

export default function ModalsProvider({ children }: React.PropsWithChildren) {
  const theme = useMantineTheme();
  return (
    <MantineModalsProvider
      modals={tengoModals}
      modalProps={{
        yOffset: '20vh',
        radius: 'md',
        withCloseButton: false,
        overlayProps: {
          color: theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        },
      }}
    >
      {children}
    </MantineModalsProvider>
  );
}
