import { forwardRef } from 'react';

import { Group, Text } from '@mantine/core';

import { Avatar } from '../../UI/Avatar/Avatar';

export type UserWithAvatarProps = {
  avatarColor: string;
  firstName: string;
  lastName: string;
  fw?: number;
  w?: string;
  [key: string]: any; // allow any other props
};

export const UserWithAvatar = forwardRef<HTMLDivElement, UserWithAvatarProps>(
  (
    { avatarColor, firstName, lastName, fw = 400, w = '220px', ...rest }: UserWithAvatarProps,
    ref,
  ) => {
    return (
      <Group ref={ref} noWrap align="center" spacing="02" w={w} py="02" {...rest}>
        <Avatar color={avatarColor}>
          {firstName[0].toUpperCase() + lastName[0].toUpperCase()}
        </Avatar>
        <Text variant="sm" fw={fw} truncate>
          {firstName} {lastName}
        </Text>
      </Group>
    );
  },
);
