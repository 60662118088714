import { Suspense, useEffect } from 'react';

import { AppShell } from '@mantine/core';
import { modals } from '@mantine/modals';

import * as Sentry from '@sentry/react';
import * as reactRouterDom from 'react-router-dom';
import { IconBallpen } from '@tabler/icons-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import posthog from 'posthog-js';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';

import { Navbar } from './UI/Navbar/Navbar.tsx';

import * as superTokens from './infra/supertokens.ts';
import { queryClient } from './api/queryClient.ts';
import { CustomLoader } from './components/CustomLoader.tsx';
import ErrorComponent from './components/ErrorComponent.tsx';
import FeatureFlagsAndActiveUserProvider, {
  useActiveUser,
} from './components/FeatureFlagsAndActiveUser.context.tsx';
import { ThemeProvider } from './components/ThemeProvider.tsx';
import ModalsProvider from './components/modals/ModalsProvider.tsx';
import { UrlHashProvider } from './hooks/useUrlHash.hook.tsx';
import './infra/hotjar.ts';
import './infra/posthog.ts';
import { BidResponsePage } from './pages/BidResponse.page.tsx';
import { BuyerSimulationPage } from './pages/BuyerSimulation.page.tsx';
import PageNotFound from './pages/PageNotFound.tsx';

const { BrowserRouter, Routes, Route } = reactRouterDom;

superTokens.init();

export default function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <SuperTokensWrapper>
          <FeatureFlagsAndActiveUserProvider>
            <ModalsProvider>
              <BrowserRouter>
                <UrlHashProvider>
                  <Routes>
                    {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
                      EmailPasswordPreBuiltUI,
                    ])}
                    <Route
                      path="/"
                      element={
                        <SessionAuth>
                          <AppWithLogin />
                        </SessionAuth>
                      }
                    >
                      {/* HOME PAGE */}
                      <Route path="/" element={<Navigate to={`/reponse`} />} />

                      {/* Buyer Simulation */}
                      <Route path="/reponse" element={<BidResponsePage />} />
                      <Route path="/reponse/:id" element={<BuyerSimulationPage />} />
                    </Route>
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </UrlHashProvider>
              </BrowserRouter>
            </ModalsProvider>
          </FeatureFlagsAndActiveUserProvider>
        </SuperTokensWrapper>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

function AppWithLogin() {
  const session = useSessionContext();
  const { activeUser, ignoreInReporting } = useActiveUser();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const onboarding = searchParams.get('onboarding');

  useEffect(() => {
    if (!session.loading && session.doesSessionExist) {
      if (import.meta.env.PROD) {
        const email = session.accessTokenPayload.email;

        heap.identify(session.userId);
        heap.addUserProperties({ email });

        posthog.identify(session.userId, { email, ignoreInReporting });
        posthog.group('company', session.accessTokenPayload.companyUniqueName);
        // we cannot identify on hotjar, that's a paying feature}
      }
    }
  }, [ignoreInReporting, session]);

  useEffect(() => {
    if (onboarding === 'true') {
      openOnboardingModal();
    }
  }, [onboarding]);

  const openOnboardingModal = () => {
    modals.openContextModal({
      fullScreen: true,
      withCloseButton: true,
      modal: 'onboarding',
      transitionProps: { transition: 'slide-up', duration: 300, timingFunction: 'linear' },
      innerProps: { step: 0 },
    });
  };

  return (
    <AppShell
      padding={0}
      header={
        <Navbar
          links={[
            {
              link: '/reponse',
              label: 'Aide à la réponse',
              icon: <IconBallpen size={16} />,
            },
          ]}
          user={activeUser}
        />
      }
    >
      <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
        <Suspense fallback={<CustomLoader title="chargement" />}>
          <Outlet />
        </Suspense>
      </Sentry.ErrorBoundary>
    </AppShell>
  );
}

// const RedirectWithParams = ({
//   to,
//   query,
//   paramNames = ['id'],
// }: {
//   to: string;
//   query?: string;
//   paramNames?: string[];
// }) => {
//   const params = useParams();
//   const pathParams = paramNames.map(name => params[name]).join('/'); // Build path from params
//   const queryString = query ? `?${query}` : '';

//   return <Navigate to={`${to}/${pathParams}${queryString}`} replace />;
// };
