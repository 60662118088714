import { Stack, Group, Text } from '@mantine/core';

import { BrandedCard } from '../../../UI/BrandedCard/BrandedCard';

import type { JudgementCriteria } from '../../../entities/BidResponse';
import { CriterionCard } from './CriterionCard';

type SummaryCardProps = {
  total: number;
  missing: number;
  criterion: JudgementCriteria[];
};

export const SummaryCard = (props: SummaryCardProps) => {
  return (
    <BrandedCard variant="filled" title="Résumé de la simulation">
      <Stack spacing="05">
        <StatsRow {...props} />
        {/* <BidSummary bidSummary={summary} /> */}
      </Stack>
    </BrandedCard>
  );
};

const StatsRow = ({ total, missing, criterion }: SummaryCardProps) => {
  return (
    <Group noWrap spacing="05" align="flax-start">
      <StatCard title="Éxigences détectées" stats={total} />
      <StatCard title="Éléments de réponse manquants" stats={missing} />
      <CriterionCard criterion={criterion} />
    </Group>
  );
};

type StatCardProps = {
  title: string;
  stats: number;
};

const StatCard = ({ title, stats }: StatCardProps) => {
  return (
    <Stack
      spacing="02"
      py="04"
      px="05"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Text variant="sm" fw={500} c="gray.5">
        {title}
      </Text>
      <Text fw="600" c="gray.9" fz="40px">
        {stats}
      </Text>
    </Stack>
  );
};

// type BidSummaryProps = {
//   bidSummary: string;
// };

// const BidSummary = ({ bidSummary }: BidSummaryProps) => {
//   return (
//     <Stack spacing="02">
//       <Text variant="sm" fw={400} c="gray.6">
//         Avis global de l'acheteur simulé
//       </Text>
//       <Group noWrap spacing="01" align="flex-end">
//         <Group noWrap spacing="02" sx={{ alignItems: 'stretch' }}>
//           <AnswerLeftBorder />
//           <Text variant="sm" fw={400} c="gray.9">
//             {bidSummary}
//           </Text>
//         </Group>
//         <AIIconSparkle style={{ minWidth: '30px' }} />
//       </Group>
//     </Stack>
//   );
// };
