import type { InstantAnalysisStatus } from './InstantAnalysisStatus';
import type { User } from './User';

export type Interaction = {
  decisionStatus?: DecisionStatus;
  decisionRenewalStatus?: DecisionRenewalStatus;
  owner?: User;
  note?: string;
  instantAnalysisStatus: InstantAnalysisStatus;
  nogoReason?: string;
  resultReason?: string;
  answeredAt?: Date;
};

export enum DecisionStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TO_ANALYZE = 'TO_ANALYZE',
  GO = 'GO',
  NOGO = 'NOGO',
  ANSWERED = 'ANSWERED',
  LOSS = 'LOSS',
  WIN = 'WIN',
}

export enum DecisionRenewalStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  TO_ANALYZE = 'TO_ANALYZE',
}
