import React from 'react';

import { Group, Popover, Stack, Text, ThemeIcon, Title } from '@mantine/core';

import { IconNotes } from '@tabler/icons-react';

import type { DataPointSource } from '../../../entities/DataPointSource';

export const SourceButton = ({ source }: { source: DataPointSource }) => {
  return (
    <Stack spacing={4}>
      <Group spacing="xxs">
        <Popover
          closeOnEscape
          shadow="md"
          middlewares={{ flip: false, shift: false, inline: false }}
          position="bottom"
        >
          <Popover.Target>
            <ThemeIcon
              sx={theme => ({
                borderRadius: '100%',
                boxShadow: theme.shadows.xs,
                background: theme.colors.gray[1],
                border: `1px solid ${theme.colors.gray[1]}`,
                ':hover': {
                  backgroundColor: theme.colors.gray[0],
                  cursor: 'pointer',
                },
              })}
              p="3px"
              w="24px"
              h="24px"
              size={14}
              color="gray.6"
              variant="outline"
            >
              <IconNotes />
            </ThemeIcon>
          </Popover.Target>
          <Popover.Dropdown
            p="lg"
            sx={{
              maxWidth: '50%',
              ':hover': {
                curser: 'pointer',
              },
            }}
          >
            <Stack spacing="md">
              <Stack spacing={0}>
                <Title order={5}>{source.docFilePath}</Title>
                {source.pageNumber && (
                  <Text variant="sm" fw={400} c="gray.6">
                    {`Page ${source.pageNumber}`}
                  </Text>
                )}
              </Stack>
              <Text size="sm" color="gray.9">
                {source.originalChunkExtract.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Text>
            </Stack>
          </Popover.Dropdown>
        </Popover>
      </Group>
    </Stack>
  );
};
