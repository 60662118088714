import { useEffect, useState } from 'react';

import { Stack, Title, Text, Radio, Box, Group, ThemeIcon } from '@mantine/core';
import type { FileWithPath } from '@mantine/dropzone';

import {
  IconPaperclip,
  IconInfoSquareRounded,
  IconAsterisk,
  IconCheck,
  IconPlus,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../UI/Button/Button';

import AIIconSparkle from '../../../assets/ai_icons/sparkle.svg?react';
import '../../../entities/BidResponse';
import type { CriteriaExtractionStatus, ProposalVersion } from '../../../entities/BidResponse';
import { ResponseDocType } from '../../../entities/BidResponse';
import { DropZoneInput } from '../../bidResponse/DropZoneInput';
import { useBidRespone } from '../useBidResponse.hook';
import { AnalysisLoader } from './AnalysisLoader';
import { useCreateProposalVersion } from './useCreateProposalVersion.hook';

type VersionsCardProps = {
  selectedProposalVersionId: number | null;
  extractionStatus: CriteriaExtractionStatus;
  bidResponseId: number;
  proposalVersions?: ProposalVersion[];
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};
export const VersionsCard = ({
  selectedProposalVersionId,
  extractionStatus,
  proposalVersions,
  bidResponseId,
  setSelectedProposalVersion,
}: VersionsCardProps) => {
  switch (extractionStatus) {
    case 'DONE':
      return (
        <DoneCard
          selectedProposalVersionId={selectedProposalVersionId}
          proposalVersions={proposalVersions}
          bidResponseId={bidResponseId}
          setSelectedProposalVersion={setSelectedProposalVersion}
        />
      );
    case 'FAILED':
      throw new Error('Failed to extract criteria');
    default:
      return <PendingCard />;
  }
};

const PendingCard = () => {
  return (
    <Stack
      px="06"
      py="05"
      spacing="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      })}
    >
      <CardHeader
        title="Analyse de l’appel d’offres en cours..."
        subtitle="Veuillez patienter pendant que  notre intelligence artificielle étudie le DCE, vous allez bientôt pouvoir télécharger votre mémoire technique pour l’analyser."
      />
      <AnalysisLoader />
    </Stack>
  );
};

type DoneCardProps = {
  selectedProposalVersionId: number | null;
  proposalVersions?: ProposalVersion[];
  bidResponseId: number;
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};

const DoneCard = ({
  selectedProposalVersionId,
  proposalVersions,
  bidResponseId,
  setSelectedProposalVersion,
}: DoneCardProps) => {
  return (
    <Stack
      px="06"
      py="05"
      spacing="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      })}
    >
      <CardHeader
        title="Évaluer votre mémoire technique"
        subtitle="Challenger sur vos mémoires techniques par notre intelligence artificielle entrainée sur les meilleures pratiques du marché"
      />
      <SuccessBadge />
      {proposalVersions?.length ? (
        <Stack>
          {selectedProposalVersionId && (
            <VersionList
              selectedProposalVersionId={selectedProposalVersionId}
              proposalVersions={proposalVersions}
              setSelectedProposalVersion={setSelectedProposalVersion}
            />
          )}
          <AdditionalVersionButton
            bidResponseId={bidResponseId}
            setSelectedProposalVersion={setSelectedProposalVersion}
          />
        </Stack>
      ) : (
        <VersionInputs
          bidResponseId={bidResponseId}
          setSelectedProposalVersion={setSelectedProposalVersion}
        />
      )}
    </Stack>
  );
};

type CardHeaderProps = {
  title: string;
  subtitle: string;
};

const CardHeader = ({ title, subtitle }: CardHeaderProps) => {
  return (
    <Stack spacing="00">
      <Title order={4}>{title}</Title>
      <Text variant="sm" fw="400" c="gray.5">
        {subtitle}
      </Text>
    </Stack>
  );
};

const SuccessBadge = () => {
  return (
    <Box
      w="fit-content"
      p="2px"
      sx={theme => ({
        borderRadius: theme.radius.xl,
        background: `linear-gradient(93.26deg, rgba(255, 255, 255, 0.4) -10.36%, rgba(112, 114, 135, 0.4) 142.1%)`,
      })}
    >
      <Group
        noWrap
        w="fit-content"
        px="04"
        py="03"
        spacing="02"
        sx={theme => ({
          borderRadius: '22px',
          borderImageSlice: 1,
          boxShadow: theme.shadows.xs,
          background: 'white',
        })}
      >
        <AIIconSparkle style={{ width: '30px' }} />
        <Text variant="xs" fw="400" c="gray.4">
          Le DCE a été analysé avec succès !
        </Text>
      </Group>
    </Box>
  );
};

type VersionListProps = {
  selectedProposalVersionId: number;
  proposalVersions: ProposalVersion[];
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};

const VersionList = ({
  proposalVersions,
  selectedProposalVersionId,
  setSelectedProposalVersion,
}: VersionListProps) => {
  const [value, setValue] = useState<string>(String(selectedProposalVersionId));

  useEffect(() => {
    setValue(String(selectedProposalVersionId));
  }, [selectedProposalVersionId]);

  // tricks to avoid flashing a new tab by navigating to the fileUrl
  const handleDownload = (fileURL: string) => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = ''; // Optional: specify a filename
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectVersion = (versionId: number) => {
    setValue(String(versionId));
    setSelectedProposalVersion(versionId);
  };

  return (
    <Radio.Group w="100%" value={value} onChange={setValue} name="selectVersion">
      {proposalVersions.map(version => {
        return (
          <Box key={version.id} onClick={() => handleSelectVersion(version.id)}>
            <Radio
              value={String(version.id)}
              label={
                <Group noWrap spacing="04">
                  <Text variant="sm" fw="500" c="gray.6">
                    V{version.version}
                  </Text>
                  {version.proposalDocs.map(doc => (
                    <Group
                      key={doc.id}
                      noWrap
                      spacing="01"
                      p="02"
                      c="gray.4"
                      bg="white"
                      sx={theme => ({
                        border: `1px solid ${theme.colors.gray[1]}`,
                        borderRadius: theme.radius.md,
                      })}
                    >
                      <IconPaperclip />
                      <Text variant="sm" fw="500" c="gray.5" maw="180px" truncate>
                        {doc.originalName}
                      </Text>
                      <Button variant="white" size="xs" onClick={() => handleDownload(doc.fileURL)}>
                        Télécharger
                      </Button>
                    </Group>
                  ))}
                </Group>
              }
              p="02"
              sx={theme => ({
                borderRadius: theme.radius.md,
                ':hover': {
                  background: theme.colors.gray[0],
                  cursor: 'pointer',
                },
              })}
              styles={{
                inner: {
                  alignSelf: 'center',
                },
              }}
            />
          </Box>
        );
      })}
    </Radio.Group>
  );
};

type AdditionalVersionButtonProps = {
  bidResponseId: number;
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};

const AdditionalVersionButton = ({
  bidResponseId,
  setSelectedProposalVersion,
}: AdditionalVersionButtonProps) => {
  const [isCreating, setIsCreating] = useState(false);

  if (!isCreating) {
    return (
      <Button
        variant="light"
        color="gray"
        leftIcon={<IconPlus />}
        size="sm"
        w="fit-content"
        onClick={() => setIsCreating(true)}
      >
        Nouvelle version
      </Button>
    );
  }

  return (
    <VersionInputs
      bidResponseId={bidResponseId}
      setSelectedProposalVersion={setSelectedProposalVersion}
    />
  );
};

type VersionInputsProps = {
  bidResponseId: number;
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};

const VersionInputs = ({ bidResponseId, setSelectedProposalVersion }: VersionInputsProps) => {
  const { refetchBidResponse } = useBidRespone(bidResponseId);
  const { creaProposalVersion } = useCreateProposalVersion();
  const navigate = useNavigate();
  const [proposalFiles, setProposalFiles] = useState<FileWithPath[]>([]);
  const [complementaryFiles, setComplementaryFiles] = useState<FileWithPath[]>([]);

  const handleDrop = (newFile: FileWithPath[], type: ResponseDocType) => {
    if (type === ResponseDocType.PROPOSAL_TECHNICAL_SUBMISSION) {
      setProposalFiles(prev => [...prev, ...newFile]);
    } else {
      setComplementaryFiles(prev => [...prev, ...newFile]);
    }
  };

  const createNewProposalVersion = () => {
    creaProposalVersion({
      bidResponseId,
      proposalFiles,
      complementaryFiles,
      onSuccessCallback: data => {
        refetchBidResponse();
        setSelectedProposalVersion(data.id);
        navigate(`/reponse/${bidResponseId}?tab=buyerSimulation`);
      },
    });
  };

  return (
    <Stack spacing="05">
      <Group noWrap spacing="02" align="flex-end" miw="960px">
        <DropZoneInput
          files={[]}
          onFileChange={files => handleDrop(files, ResponseDocType.PROPOSAL_TECHNICAL_SUBMISSION)}
          label="Chargez une version de votre réponse"
        >
          <Stack spacing="01" w="100%">
            <Group noWrap spacing="02" c="gray.4" m="auto">
              {proposalFiles.length ? (
                <ThemeIcon color="teal.6" size="sm">
                  <IconCheck />
                </ThemeIcon>
              ) : (
                <IconPaperclip />
              )}
              <Group spacing="01">
                <Text variant="sm" fw="500" c="primary.6">
                  Chargez
                </Text>
                <Text variant="sm" fw="500" c="gray.5">
                  votre mémoire technique
                </Text>
                <ThemeIcon
                  size="xs"
                  variant="light"
                  sx={theme => ({
                    backgroundColor: theme.colors.primary[1],
                    color: theme.colors.primary[6],
                    borderRadius: theme.radius.xl,
                  })}
                >
                  <IconAsterisk size={8} stroke={3} />
                </ThemeIcon>
              </Group>
              <IconInfoSquareRounded />
            </Group>
            <Text variant="xs" fw="400" c="gray.4" m="auto">
              Fichier word ou pdf
            </Text>
          </Stack>
        </DropZoneInput>
        <DropZoneInput
          files={[]}
          onFileChange={files => handleDrop(files, ResponseDocType.PROPOSAL_APPENDICES)}
        >
          <Stack spacing="01" w="100%">
            <Group noWrap spacing="02" c="gray.4" m="auto">
              {complementaryFiles.length ? (
                <ThemeIcon color="teal.6" size="sm">
                  <IconCheck />
                </ThemeIcon>
              ) : (
                <IconPaperclip />
              )}
              <Group spacing="01">
                <Text variant="sm" fw="500" c="primary.6">
                  Chargez
                </Text>
                <Text variant="sm" fw="500" c="gray.5">
                  vos annexes
                </Text>
              </Group>
              <IconInfoSquareRounded />
            </Group>
            <Text variant="xs" fw="400" c="gray.4" m="auto">
              Fichier word ou pdf
            </Text>
          </Stack>
        </DropZoneInput>
      </Group>
      <Button w="fit-content" disabled={!proposalFiles.length} onClick={createNewProposalVersion}>
        Lancer la simulation acheteur
      </Button>
    </Stack>
  );
};
