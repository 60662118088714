import { useSuspenseQuery } from '@tanstack/react-query';

import { getProposalVersion } from '../../../api/magellan';
import { queryClient } from '../../../api/queryClient';

export const useProposalVersion = (bidResponseId: number, proposalVersionId: number) => {
  const queryKey = [getProposalVersion.name, bidResponseId, proposalVersionId];
  const queryFn = async () => {
    const res = await getProposalVersion(bidResponseId, proposalVersionId);
    return res;
  };

  const { data } = useSuspenseQuery({
    queryKey,
    queryFn,
    refetchInterval: query => {
      if (query.state.data?.extractionStatus === 'RUNNING') {
        return 5000;
      }
      return false;
    },
  });

  const refetchProposalVersion = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  // const proposalVersion = {
  //   ...data,
  //   expectationEvaluations: data?.expectationEvaluations?.sort(
  //     (a, b) => a.bidExpectation.id - b.bidExpectation.id,
  //   ),
  // };
  return {
    proposalVersion: data,
    refetchProposalVersion,
  };
};
