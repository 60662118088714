import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import {
  getThreads,
  createComment,
  createThread,
  deleteComment,
  updateComment,
} from '../../../../api/magellan';
import { queryClient } from '../../../../api/queryClient';
import type { RichTextJson } from '../../../../entities/Comment';
import { ThreadElementType } from '../../../../entities/Thread';
import { useOnTheFlyQuestionsSetsAnalysis } from '../../../../hooks/useOnTheFlyQuestionAnalysis';
import { useQuestionsSetsAnalysis } from '../../../../hooks/useQuestionsSetsAnalysis';

type CreateThreadType = {
  content: RichTextJson;
  threadId?: number;
  instantAnalysisAnswerId?: number;
};

type DeleteCommentType = {
  commentId: number;
};

type UpdateCommentType = {
  commentId: number;
  content: RichTextJson;
};

export function useThreads(tenderId: number) {
  const queryKey = [getThreads.name, tenderId];
  const queryFn = async () => getThreads(tenderId, ThreadElementType.INTERACTION);
  const { refetch: refetchAnalysis } = useQuestionsSetsAnalysis(tenderId);
  const { refetch: refetchOnTheFlyAnalysis } = useOnTheFlyQuestionsSetsAnalysis(tenderId);
  const { data: threads } = useSuspenseQuery({
    queryKey,
    queryFn,
  });

  const refetch = () => queryClient.invalidateQueries({ queryKey });

  const threadMutation = useMutation({
    mutationFn: ({ content, threadId, instantAnalysisAnswerId }: CreateThreadType) => {
      if (threadId) {
        return createComment(tenderId, threadId, content, instantAnalysisAnswerId);
      }
      return createThread(tenderId, content, instantAnalysisAnswerId);
    },
    onSuccess: (_data, variable) => {
      refetch();
      if (variable.instantAnalysisAnswerId) {
        refetchAnalysis();
        refetchOnTheFlyAnalysis();
      }
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: ({ commentId }: DeleteCommentType) => deleteComment(commentId),
    onSuccess: refetch,
  });

  const updateCommentMutation = useMutation({
    mutationFn: ({ commentId, content }: UpdateCommentType) => updateComment(commentId, content),
    onSuccess: refetch,
  });

  const commentCounter = threads?.reduce((acc, thread) => acc + thread.comments.length, 0) || 0;

  return {
    threads,
    createThread: (props: Omit<CreateThreadType, 'threadId'>) => threadMutation.mutate(props),
    createComment: (props: CreateThreadType) => threadMutation.mutate(props),
    deleteComment: (commentId: number) => deleteCommentMutation.mutate({ commentId }),
    updateComment: (commentId: number, content: RichTextJson) =>
      updateCommentMutation.mutate({ commentId, content }),
    commentCounter,
  };
}
