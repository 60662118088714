import { useState, useEffect, useCallback } from 'react';

import type { InstantAnalysisStatus } from '../entities/InstantAnalysisStatus';

const MAX_RETRIES = 60;
const RETRY_DELAY = 5000; // 5 seconds

export function useInstantAnalysis(
  refetch: () => void,
  initialStatus: InstantAnalysisStatus | undefined,
) {
  const [status, setStatus] = useState<InstantAnalysisStatus | null>(initialStatus || null);
  const [fetchAttempts, setFetchAttempts] = useState<number | null>(null);

  useEffect(() => {
    const fetchInstantAnalysisWithRetry = async () => {
      await refetch();
      if (status !== 'RUNNING') {
        setStatus(status);
        setFetchAttempts(null);
        return;
      }
      const timeToWait = fetchAttempts && fetchAttempts < 1 ? 1000 : RETRY_DELAY;
      await delay(timeToWait);
      setFetchAttempts(prev => {
        if (prev === null) {
          return null;
        }
        return prev + 1;
      });
    };

    if (fetchAttempts !== null && fetchAttempts < MAX_RETRIES) {
      fetchInstantAnalysisWithRetry();
    }
  }, [fetchAttempts, refetch, status]);

  // initial fetch
  useEffect(() => {
    const initialFetchInstantAnalysis = async () => {
      await refetch();
      setStatus(status);
      if (status === 'RUNNING') {
        setFetchAttempts(0);
      }
    };

    if (status === null) {
      initialFetchInstantAnalysis();
    }
  }, [refetch, status]);

  const retry = useCallback(() => {
    setFetchAttempts(0);
  }, []);

  const onTriggerInstantAnalysis = useCallback(async () => {
    setFetchAttempts(0);
    setStatus('RUNNING');
  }, []);

  return {
    status,
    retry: fetchAttempts === MAX_RETRIES ? retry : undefined,
    onTriggerInstantAnalysis,
  };
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
